<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12 sm5 md7 text-left>
        <span class="mainHeader">STAFF</span>
      </v-flex>
      <v-flex xs12 sm3 md2 text-right>
        <v-text-field
          background-color="white"
          v-model="keyword"
          clearable
          hide-details="auto"
          color="#b234a9"
          placeholder="Search"
          outlined
          class="rounded-xl"
          dense
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-flex>
     
      <!-- <v-flex xs2 text-right>
              <v-btn dark color="#3F053C" @click="addRole = true">
                Add Roles
              </v-btn>
            </v-flex> -->
      <v-flex xs12 sm4 md3 pt-2 pt-sm-0  text-right >
        <v-btn dark color="#3F053C" @click="addStaff = true" class="mr-2"> Add Staff </v-btn>
      <!-- </v-flex>
      <v-flex xs1> -->
        <v-btn dark color="#3F053C" @click="$router.push('/rolesList')"> Roles </v-btn>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          elevation="0"
          class="rounded-lg"
          tile
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
          <v-layout wrap pa-4 v-if="staffList">
            <v-flex xs12 v-if="staffList.length>0">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th
                        class="text-left tablefont"

                      >
                        <b>S.No.</b>
                      </th>
                      <th
                        class="text-left tablefont"

                      >
                        <b>User Name</b>
                      </th>
                      <th
                        class="text-left tablefont"

                      >
                        <b>Name</b>
                      </th>
                      <th
                        class="text-left tablefont"

                      >
                        <b>Phone No</b>
                      </th>
                      <th
                        class="text-left tablefont"

                      >
                        <b>Email address</b>
                      </th>
                      <th
                        class="text-left tablefont"

                      >
                        <b>Role</b>
                      </th>
                      <th
                        class="text-left tablefont"

                      >
                        <b>Staff Code</b>
                      </th>
                      <th
                        class="text-center tablefont"

                      >
                        <b>Status</b>
                      </th>
                      <th
                        class="text-left tablefont"

                      >
                        <b>Action</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(value, i) in staffList" :key="i" class="table">
                      <!-- <td>{{ i + 1 }}</td> -->
                      <td>
                        <span v-if="page == 1">
                          {{ i + 1 }}
                        </span>
                        <span v-else>
                          {{ i + 1 + 20 * (page - 1) }}
                        </span>
                      </td>
                      <td>
                        <span v-if="value.username">{{ value.username }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.firstname"> {{
                          value.firstname
                        }}   </span>
                         <span v-if="value.lastname"> {{
                          value.lastname
                        }}</span>
                        <span v-else></span>
                      </td>
                      <td>
                        <span v-if="value.phone">{{ value.phone }}</span>
                        <span v-else>-</span>
                      </td>

                      <td>
                        <span v-if="value.email">{{ value.email }}</span>
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.subRoleId">
                          {{ value.subRoleId.roles }}</span
                        >
                        <span v-else>-</span>
                      </td>
                      <td>
                        <span v-if="value.staffCode">
                          {{ value.staffCode }}</span
                        >
                        <span v-else>-</span>
                      </td>
                      <td class="text-center">
                        <v-btn
                          v-if="value.staffStatus == 'Inactive'"
                          @click="
                            (changestatus2 = true), (statusId = value._id)
                          "
                          small
                          elevation="0"
                          color="red"
                          style="font-family:DmSansBold; border-radius: 7px;"
                          dark
                          height="25px"
                        >
                          Inactive
                        </v-btn>
                        <v-btn
                          v-if="value.staffStatus == 'Expire'"
                          small
                          elevation="0"
                          color="grey"
                          dark
                          style="font-family:DmSansBold; border-radius: 7px;"
                          height="25px"
                        >
                          Expired
                        </v-btn>
                        <v-btn
                          height="25px"
                          v-if="value.staffStatus == 'Active'"
                          @click="(changestatus = true), (statusId = value._id)"
                          small
                          elevation="0"
                          color="green"
                          dark
                          style="font-family:DmSansBold; border-radius: 7px;"
                        >
                          Active
                        </v-btn>
                      </td>
                      <td>
                        <v-icon
                          size="18"
                          color="#3F053C"
                          class="pr-2"
                          title="Edit"
                          :disabled="value.staffStatus == 'Expire'?true:false"
                          style="cursor: pointer;"
                          @click="(curItem = value), (editdialogue = true)"
                          >mdi-pencil</v-icon
                        >
                        <v-icon
                          size="18"
                          color="#3F053C"
                          title="Delete"
                          :disabled="value.staffStatus == 'Expire'?true:false"
                          style="cursor: pointer"
                          @click="(deletedialog = true), (curId = value._id)"
                          >mdi-delete</v-icon
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-flex>
            <v-flex xs12 v-else pa-4 text-center class="tablefont1"> No data found </v-flex>
          </v-layout>
          <v-layout wrap v-if="staffList">
            <v-flex xs12 pt-4 v-if="staffList.length>0">
              <v-pagination
                small
                color="#3F053C"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
            </v-flex>
          </v-layout>
        </v-card>
        <v-dialog width="400px" v-model="addRole">
          <v-card width="400px">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Add Role</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="addRole = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-layout wrap>
              <v-flex xs12 px-4 pt-4>
                <v-text-field
                  
                  placeholder="Roles"
                  color="#b234a9"
                  v-model="roles"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 px-4 py-4>
                <v-select
                  :items="adminList"
                  v-model="jewelleryId"
                  placeholder="Select Jewellery"
                  item-text="jewelleryName"
                  item-value="_id"
                  outlined
                  hide-details="auto"
                  dense
                ></v-select>
              </v-flex> -->
            </v-layout>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <!-- <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="addRole = false"
                >Cancel</v-btn
              > -->
              <v-btn
                color="#3F053C"
                class="button1"
                dark
                @click="AddRole()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="800px" v-model="addStaff">
          <v-form v-model="addcat" ref="addcat" @submit.prevent>

          <v-card width="800px" class="rounded-lg pa-2" >
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Add Staff</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="(addStaff = false)(resetForm())"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-form ref="myForm">
            <v-layout wrap>
              <v-flex xs6>
                <v-layout wrap>
                  <v-flex xs12 px-4 text-left class="tablefont">First Name</v-flex>
                  <v-flex xs12 px-4>
                    <v-text-field
                      
                      color="#b234a9"
                      placeholder="Name"
                      v-model="firstname"
                      outlined
                      :rules="[rules.required]"
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                 
                  <v-flex xs12 px-4 pt-4 text-left class="tablefont">Staff Code</v-flex>
                  <v-flex xs12 px-4 >
                    <v-text-field
                      
                      color="#b234a9"
                      placeholder="Staff Code"
                      :rules="[rules.required]"
                      v-model="staffCode"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-4 pt-4 text-left pb-2>
                    <v-flex xs12  text-left class="tablefont">Role</v-flex>
                    <v-select
                      :items="rolesList"
                      v-model="roleId"
                      color="#b234a9"
                      :rules="[rules.required]"
                      placeholder="Role"
                      item-text="roles"
                      item-value="_id"
                      outlined
                      hide-details="auto"
                      dense
                    ></v-select>
                  </v-flex>
                  <v-flex xs4 px-4 pt-1 text-center align-self-center>
                    <v-chip
                      small
                      outlined
                      tile 
                      color="#3F053C"
                      @click="addRole = true"
                    >
                      <v-icon small>mdi-plus</v-icon> Add Roles
                    </v-chip>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout wrap>
                  <v-flex xs12 px-4 text-left class="tablefont">Last Name</v-flex>
                  <v-flex xs12 px-4>
                    <v-text-field
                      
                      color="#b234a9"
                      placeholder="Name"
                      v-model="lastname"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-4 pb-4>
                    
                    <v-flex xs12 text-left pt-4  class="tablefont">Phone</v-flex>
                    <v-text-field
                      v-model="phone"
                      dense
                      type="number"
                      :rules="phoneRules"
                      placeholder="Phone"
                      color="#b234a9"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-4 pb-4>
                    <v-flex xs12 text-left class="tablefont">Email</v-flex>
                    <v-text-field
                      v-model="email"
                      dense
                      :rules="[rules.email]"
                      placeholder="Email"
                      color="#b234a9"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-form>
            <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <!-- <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="(addStaff = false)(resetForm())"
                >Cancel</v-btn
              > -->
              <v-btn
                color="#3F053C"
                class="button1"
                dark
                @click="validate()"
                >OK</v-btn
              >
            </v-card-actions>
          </v-card>
          </v-form>
        </v-dialog>
        <v-dialog width="600px" v-model="editdialogue">
          <v-form v-model="addcat2" ref="addcat2" @submit.prevent>

          <v-card width="600px" class="pa-2">
            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Edit Staff</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="editdialogue = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-layout wrap>
              <v-flex xs6>
                <v-layout wrap>
                  <v-flex xs12 px-4>
                    <v-flex xs12 text-left class="tablefont">First Name</v-flex>
                    <v-text-field
                      
                      placeholder="Name"
                      color="#b234a9"
                      v-model="curItem.firstname"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-4 pt-4>
                    <v-flex xs12 text-left class="tablefont">Last Name</v-flex>
                    <v-text-field
                      
                      placeholder="Name"
                      color="#b234a9"
                      v-model="curItem.lastname"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-4 py-4>
                    <v-flex xs12 text-left class="tablefont">Role</v-flex>
                    <v-select
                      :items="rolesList"
                      v-model="curItem.subRoleId"
                      placeholder="Role"
                      item-text="roles"
                      color="#b234a9" 
                      item-value="_id"
                      outlined
                      hide-details="auto"
                      dense
                    ></v-select>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs6>
                <v-layout wrap>
                  <v-flex xs12 px-4 pb-4>
                    <v-flex xs12 text-left class="tablefont">Phone</v-flex>
                    <v-text-field
                      v-model="curItem.phone"
                      dense 
                      :rules="phoneRules"
                      placeholder="Phone"
                      color="#b234a9"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 px-4 pb-4>
                    <v-flex xs12 text-left class="tablefont">Email</v-flex>
                    <v-text-field
                      v-model="curItem.email"
                      dense
                      :rules="[rules.email]"
                      placeholder="Email"
                      color="#b234a9"
                      outlined
                      hide-details="auto"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs6>
                    <v-spacer></v-spacer>
                  </v-flex>
                  <v-flex xs6 pr-4 pt-7>
                    <v-btn
                color="#3F053C"
                class="button1"
                dark block
                @click="editValidate()"
                >OK</v-btn
              >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>

            <!-- <v-card-actions class="pt-3">
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                text
                class="body-2 font-weight-bold"
                @click="editdialogue = false"
                >Cancel</v-btn
              >
              <v-btn
                color="#3F053C"
                class="button1"
                dark
                @click="editValidate()"
                >OK</v-btn
              >
            </v-card-actions> -->
          </v-card>
          </v-form>
        </v-dialog>
        <v-dialog width="300px" v-model="changestatus">
          <v-card width="300px">
            <v-layout wrap dense flat justify-center class="body-2">
              <v-flex xs10 pl-4  pt-6 text-center
                ><span style="color: #3f053c; font-size: 19px"
                  >Change Status</span
                ></v-flex
              >
              <v-flex xs pt-1> 
                <v-icon
                  color="grey"
                  text
                  class="font-weight-bold"
                  @click="changestatus = false"
                  >mdi-close</v-icon
                ></v-flex
              >
              <v-flex xs8 text-center pt-3 >
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-flex xs12 class="pa-2" style="font-family:DmSansBold;"
              >Do You wish to Disable <br />
              this Staff Member?
            </v-flex>
            <v-card-actions class="pt-3">
              <v-layout wrap justify-center px-4 pb-4 >
                <v-flex xs9>
                  <v-btn
                    pl-3
                    color="#690964"
                    class="body-2 font-weight-bold"
                    dark
                    @click="changestaffstatus()"
                    >YES</v-btn
                  >
                  <v-btn
                    color="#3F053C"
                    dark
                    class="body-2 font-weight-bold ml-2"
                    @click="changestatus = false"
                    >NO</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="300px" v-model="changestatus2">
          <v-card width="300px">
            <v-layout wrap dense flat justify-center>
              <v-flex xs10 pt-4 text-center
                ><span style="color: #3f053c; font-size: 19px"
                  >Change Status</span
                ></v-flex
              >
              <v-flex xs1 pt-1>
                <v-icon
                  color="grey"
                  text
                  class="font-weight-bold"
                  @click="changestatus2 = false"
                  >mdi-close</v-icon
                ></v-flex
              >
              <v-flex xs8 text-center pt-3>
                <v-divider></v-divider>
              </v-flex>
            </v-layout>
            <v-flex xs12 class="pa-2" style="font-family:DmSansBold;"
              >Activate this Staff Member
              <br />
              Back?
            </v-flex>
            <v-card-actions class="pt-3">
              <v-layout wrap justify-center px-4 pb-4 >
                <v-flex xs9 >
                  <v-btn
                    color="#690964"
                    class="body-2 font-weight-bold"
                    dark
                    @click="changestaffstatus2()"
                    >YES</v-btn
                  >
                  <v-btn
                    color="#3F053C"
                    dark
                    class="body-2 font-weight-bold ml-2"
                    @click="changestatus = false"
                    >NO</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog width="400px" v-model="deletedialog">
          <v-card width="400px" class="pa-4">
          

            <v-layout wrap justify-center>
                <v-flex xs11 text-center><span style="color:#3F053C;" class="menuhead1">Delete</span></v-flex>
                <v-flex xs1 text-right>
                  <v-icon
                  @click="deletedialog = false"
                    color="#3F053C"
                    >mdi-close-box</v-icon
                  ></v-flex
                >
                <v-flex xs8 text-center py-4 >
              <v-divider></v-divider>
            </v-flex>
              </v-layout>
            <v-card-text class="pa-4 tablefont"
              >Make this Staff Expired<br />
              or<br />
              Delete staff from the List
            </v-card-text>
            <v-card-actions>
              <v-layout wrap justify-center>
                <v-flex xs9>
                  <v-btn
                    color="#690964"
                    class="button1"
                    dark
                    @click="deleteStaff()"
                    >Delete</v-btn
                  >
                  <v-btn
                    color="#3F053C"
                    class="button1 ml-2"
                    dark
                    @click="expireStaff()"
                    >EXPIRED</v-btn
                  >
                </v-flex>
              </v-layout>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      addcat: false,
      addcat2: false,
      page: 1,
      Pagelength: null,
      statusId: null,
      staffstatus: "Active",
      addRole: false,
      roles: null,
      jewelleryId: null,
      rolesList: [],
      firstname: null,
      lastname: null,
      staffCode: null,
      roleId: null,
      phone: null,
      email: null,
      addStaff: false,
      positionList: null,
      staffList: [],
      curItem: [],
      curId: null,
      editdialogue: false,
      deletedialog: false,
      changestatus: false,
      changestatus2: false,
      keyword: null,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      phoneRules: [
        (v) => !!v || "phone is required",
        (v) => (v && v.length <= 10) || "invalid number",
        (v) => (v && v.length >= 10) || "invalid number",
        (v) => /^\d{10}$/.test(v) || "phone number must be a number",
      ],
    };
  },
  watch:{
    keyword(){
    this.getStaff();
    },
    page(){
    this.getStaff();
    },
  },
  mounted() {
    this.getRoless();
    this.getStaff();
  },
  computed: {
    appUser() {
      return this.$store.state.userData;
    },
  },
  methods: {
    resetForm() {
      this.$refs.myForm.reset();
    },
    validate() {
       if (!this.firstname) {
        this.msg = "Please enter firstname";
        this.showsnackbar = true;
        return;
      }else if (!this.staffCode) {
        this.msg = "Please enter staff code";
        this.showsnackbar = true;
        return;
      }  else if (!this.roleId) {
        this.msg = "Please choose role";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please enter phone";
        this.showsnackbar = true;
        return;
      } else if (!this.email) {
        this.msg = "Please enter email";
        this.showsnackbar = true;
        return;
      } 
      else {
        this.AddStaff();
      }
    },
    editValidate() {
       if (!this.curItem.firstname) {
        this.msg = "Please enter firstname";
        this.showsnackbar = true;
        return;
      }  else if (!this.curItem.subRoleId) {
        this.msg = "Please choose role";
        this.showsnackbar = true;
        return;
      } else if (!this.curItem.phone) {
        this.msg = "Please enter phone";
        this.showsnackbar = true;
        return;
      } else if (!this.curItem.email) {
        this.msg = "Please enter email";
        this.showsnackbar = true;
        return;
      } 
      else {
        this.editStaff();
      }
    },
    getStaff() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/user/all/staff",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          page: this.page,
          count: 20,
          keyword:this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.staffList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    getRoless() {
      this.appLoading = true;
      axios({
        method: "get",
        url: "/getalllist/roles",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.appUser._id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.rolesList = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    AddRole() {
      axios({
        url: "/add/roles",
        method: "POST",
        data: {
          roles: this.roles,
          jewelleryId: this.jewelleryId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.addRole = false;
            this.jewelleryId = null;
            this.roles = null;
            this.getRoless();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    AddStaff() {
      if (this.$refs.addcat.validate()) {
      axios({
        url: "/user/add",
        method: "POST",
        data: {
          roleId: this.roleId,
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          phone: this.phone,
          staffCode: this.staffCode,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.addStaff = false;
            this.phone = null;
            this.roleId = null;
            this.email = null;
            this.firstname = null;
            this.lastname = null;
            this.staffCode = null;
            this.$refs.addcat.resetValidation();

            this.getStaff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
      }
    },
    editStaff() {
      if (this.$refs.addcat2.validate()) {

      axios({
        url: "/user/adminedit",
        method: "POST",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: {
          id: this.curItem._id,
          subRoleId: this.curItem.subRoleId,
          firstname: this.curItem.firstname,
          lastname: this.curItem.lastname,
          email: this.curItem.email,
          phone: this.curItem.phone,
        },
      })
        .then((response) => {
          this.editdialogue = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getStaff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getStaff();

          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
      }
    },
    deleteStaff() {
      axios({
        url: "/user/remove",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.deletedialog = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getStaff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    expireStaff() {
      axios({
        url: "/user/expire",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.curId,
        },
      })
        .then((response) => {
          this.deletedialog = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.getStaff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changestaffstatus() {
      axios({
        url: "/change/status",
        method: "POST",
        data: {
          id: this.statusId,
          status: "Inactive",
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.changestatus = false;
            this.getStaff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    changestaffstatus2() {
      axios({
        url: "/change/status",
        method: "POST",
        data: {
          id: this.statusId,
          status: "Active",
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.changestatus2 = false;
            this.getStaff();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
